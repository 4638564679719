// Create react router with the following routes:
// / - Home
// /aws-learn - AWSLearn

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import AWSLearn from "./pages/AWSLearn";
import PageNotFound from "./pages/PageNotFound";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/aws-learning" element={<AWSLearn />} />

        {/* 404 */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default App;

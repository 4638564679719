import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import axios from "axios";
import Markdown from 'react-markdown'

// Components
import Container from "../components/Container/Container";
const Navbar = lazy(() => import("../components/Navbar/Navbar"));
const SocialMediaIcons = lazy(() => import("../components/socialMediaIcons/SocialMediaIcons"));
const FixedEmail = lazy(() => import("../components/fixedEmail/FixedEmail"));

// Page Components
const Footer = lazy(() => import("../pageComponents/Footer/Footer"));

const AWSLearn = () => {
  const [content, setContent] = useState("");
  // Call https://github.com/matthew-boston/aws-learning/blob/main/welcome.md and display the content

  const getData = useCallback(async (path) => {
    const response = await axios.get(`https://raw.githubusercontent.com/matthew-boston/aws-learning/main/${path}.md`);
    setContent(response.data);
  }, []);

  useEffect(() => {
    // Get path from URL and remove /aws-learning
    const path = window.location.pathname.replace("/aws-learning", "");

    // if path is empty, set path to welcome
    if (path === "") {
      getData("welcome");
    } else {
      getData(path);
    }
  }, [getData]);

  return (
    <Container>
      {/* Fixed */}

      <Suspense fallback={<></>}>
        <SocialMediaIcons />
      </Suspense>
      <Suspense fallback={<></>}>
        <FixedEmail />
      </Suspense>

      {/* Non-Fixed */}
      <Suspense fallback={<></>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<></>}>
        {/* Print out readme content in html */}
        <div className="aws-learning-content-area">
          <Markdown>{content}</Markdown>
        </div>
      </Suspense>

      <Suspense fallback={<></>}>
        <Footer />
      </Suspense>
    </Container>
  );
};

export default AWSLearn;

import React, { lazy, Suspense } from "react";

// Components
import Container from "../components/Container/Container";
const Navbar = lazy(() => import("../components/Navbar/Navbar"));
const SocialMediaIcons = lazy(() => import("../components/socialMediaIcons/SocialMediaIcons"));
const FixedEmail = lazy(() => import("../components/fixedEmail/FixedEmail"));
const Divider = lazy(() => import("../components/Divider/Divider"));

// Page Components
const Landing = lazy(() => import("../pageComponents/Landing/Landing"));
const About = lazy(() => import("../pageComponents/About/About"));
const Projects = lazy(() => import("../pageComponents/Projects/Projects"));
const Blog = lazy(() => import("../pageComponents/Blog/Blog"));
const Contact = lazy(() => import("../pageComponents/Contact/Contact"));
const Footer = lazy(() => import("../pageComponents/Footer/Footer"));

const Home = () => {
  return (
    <Container>
      {/* Fixed */}

      <Suspense fallback={<></>}>
        <SocialMediaIcons />
      </Suspense>
      <Suspense fallback={<></>}>
        <FixedEmail />
      </Suspense>

      {/* Non-Fixed */}
      <Suspense fallback={<></>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<></>}>
        <Landing />
      </Suspense>
      <Suspense fallback={<></>}>
        <About />
      </Suspense>
      <Suspense fallback={<></>}>
        <Projects />
      </Suspense>
      <Suspense fallback={<></>}>
        <Blog />
      </Suspense>
      <Suspense fallback={<></>}>
        <Divider />
      </Suspense>
      <Suspense fallback={<></>}>
        <Contact />
      </Suspense>
      <Suspense fallback={<></>}>
        <Footer />
      </Suspense>
    </Container>
  );
};

export default Home;

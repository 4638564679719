import React from "react";

// Components
import Container from "../components/Container/Container";
import PageNotFoundComp from "../pageComponents/PageNotFoundComp/PageNotFoundComp";


const PageNotFound = () => {
  return (
    <Container>
      <PageNotFoundComp />
    </Container>
  );
};

export default PageNotFound;

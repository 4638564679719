// Create 404 page

import React from "react";

// Components
import { Link } from "react-router-dom";

const PageNotFoundComp = () => {
  return (
    <div className="page-not-found">
      <h1 className="page-not-found__title">404</h1>
      <h2 className="page-not-found__subtitle">Page Not Found</h2>
      <Link className="btn" to="/">Go Home</Link>
    </div>
  );
};


export default PageNotFoundComp;